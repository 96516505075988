import React from 'react';
import { graphql } from 'gatsby';

import {
	Container,
	Typography,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	withStyles,
} from '@material-ui/core';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';

const useStyles = makeStyles((theme) => ({
	headerCell: {
		background: '#002D5C',
		border: '2px solid #C3CAD9',
		// borderRadius: '20px 0px 0px 0px',
	},
	header: {
		fontWeight: 600,
		color: 'white',
	},
}));

const StyledCell = withStyles({
	root: {
		border: '2px solid #C3CAD9',
	},
})(TableCell);

const DPASubProcessorsPage = ({ data, location }) => {
	const subProcessor = data.dpa.edges[0].node;
	const classes = useStyles();

	const { hero, _rawSubProcessors, metaTitle, metaDescription } = subProcessor;

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} noindex={true} />
			<Hero hero={hero} />
			<Container>
				<TableContainer style={{ padding: '2rem 0' }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell align='left' className={classes.headerCell}>
									<Typography variant='h5' className={classes.header}>
										Provider
									</Typography>
								</TableCell>
								<TableCell align='left' className={classes.headerCell}>
									<Typography variant='h5' className={classes.header}>
										Purpose
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{_rawSubProcessors.map((sub, index) => (
								<TableRow key={index}>
									<StyledCell align='left'>
										<Typography variant='body1'>{sub.provider}</Typography>
									</StyledCell>
									<StyledCell align='left'>
										<Typography variant='body1'>{sub.purpose}</Typography>
									</StyledCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
			<div style={{ marginTop: '12rem' }}>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query DPASubProcessorsPageQuery {
		dpa: allSanityDpaSubProcessors {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					_rawSubProcessors
				}
			}
		}
	}
`;

export default DPASubProcessorsPage;
